<template>
  <div class="body">
    <article class="banner">
      <div class="layout">
        <div class="inner">
          <h2><svg class="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 232 72" width="232" height="72">
            <g fill="currentColor">
              <path d="M26.427 36.458l2.221-8.024h-6.059l1.094-10.235h2.931l2.22-8.023h-4.293l.543-5.075h-7.971l-.602 5.075H8.653l-1.549 8.023h8.455l-.122 1.032H8.924L0 62.449h8.004l5.419-26.24L9.35 70.54l9.124-3.607 3.258-30.475h4.695z"/>
              <path d="M60.039 51.151h-8.073l-1.176 3.274h-9.205l-.492-3.274h-7.989l.493 3.274H21.298l-.838 8.024h48.894l.838-8.024H58.863l1.176-3.274z"/>
              <path d="M88.397 6.57h-8.13L67.878 34.049h7.713l-4.645 36.262 9.064-1.886 4.895-44.902h-4.152L88.397 6.57zM44.485 22.571l-3.164 8.024H54.59l1.178-2.4.557 2.4h8.338l-1.104-4.759h4.299l2.22-8.024h-8.381l-.306-1.322h10.741l2.22-8.024H59.529l-.44-1.896h-8.338l3.712 16.001h-9.978zm-7.291-7.977l-.647 1.486h-7.635l-2.221 8.024h6.365l-2.803 6.443h9.3l7.92-17.556-2.071-6.421H31.004l-2.221 8.024h8.411zm25.302 35.49l3.115-18.263H30.677l-3.115 18.263h24.114l2.213-8.024h-16.35l.232-2.215h18.852l-2.244 10.239h8.117zm24.985 4.341l-.838 8.024h27.426l7.969-8.024h-13.743l2.388-22.878h16.2l.837-8.024h-16.199l1.803-17.267-8.961 2.664-1.323 14.603H87.343l-.837 8.024h15.806l-2.073 22.878H87.481zm140.796-17.254l3.672-23.341a3.17 3.17 0 0 0-.637-2.603 2.906 2.906 0 0 0-2.386-1.083h-30.45l-3.718 3.744h24.901q1.22 0 1.027 1.229l-2.88 18.311h-28.31L186.04 55.4l7.344 7.395h31.444l.58-3.686h-28.775l3.45-21.938z"/>
              <path d="M202.025 0h-20.072l-28.421 28.616L125.111 0h-9.263l33.053 33.279L110.444 72h20.071l28.422-28.616L187.358 72h9.263l-33.053-33.279L202.025 0z"/>
            </g>
          </svg>单排厢式运输车</h2>
          <p class="slogan">通过好<span>｜</span>装的多<span>｜</span>送得快<span>｜</span>用的省</p>
          <span class="btnBook" @click="showPopup">预约抢购</span>

          <img src="../../assets/v4/x2/x2_0.png" width="1440" height="490" />
        </div>
      </div>
    </article>

    <div class="box volumeBox">
      <div class="layout">
        <div class="inner">
          <div class="summary">
            <h3>容积大</h3>
            <p>巨能装，双倍容积，适合大件运输</p>
          </div>
          <img src="../../assets/v4/x2/x2_1.png" width="1170" height="700" />
        </div>
      </div>
    </div>

    <div class="box passBox">
      <div class="layout">
        <div class="inner">
          <div class="summary">
            <h3>轻松通行</h3>
            <p>车身窄，轻松通过窄街小巷</p>
          </div>
          <div class="img">
            <img src="../../assets/v4/x2/x2_2.jpg" width="980" height="840" />
          </div>
        </div>
      </div>
    </div>

    <div class="box qualityBox">
      <div class="layout">
        <div class="inner">
          <div class="summary">
            <h3>质量好配置高</h3>
            <p>乘用级安全车身<span class="mHide">、</span><br class="mShow" />智能网联舒适配置应有尽有</p>
          </div>
          <img src="../../assets/v4/x2/x2_3.png" width="1130" height="530" />
        </div>
      </div>
    </div>

    <div class="box paramsBox">
      <div class="layout">
        <div class="inner">
          <div class="img">
            <img src="../../assets/v4/x2/x2_4.png" width="950" height="580" />
          </div>
          <div class="params">
            <h3>性能参数</h3>
            <ul class="list">
              <li>
                <span class="t">尺寸参数(mm)</span>
                <span class="v">4200×1570×1995</span>
              </li>
              <li>
                <span class="t">综合工況续航里程(km)</span>
                <span class="v">120/180</span>
              </li>
              <li>
                <span class="t">动力电池总电量(kWh)</span>
                <span class="v">10/20</span>
              </li>
              <li>
                <span class="t">前/后轮距(mm)</span>
                <span class="v">1250/1250</span>
              </li>
              <li>
                <span class="t">最高时速(km/h)</span>
                <span class="v">71</span>
              </li>
              <li>
                <span class="t">BMS电池管理系统</span>
                <span class="v">支持</span>
              </li>
              <li>
                <span class="t">最大总质量(kg)</span>
                <span class="v">1700</span>
              </li>
              <li>
                <span class="t">最大爬坡度(%</span>
                <span class="v">>20</span>
              </li>
              <li>
                <span class="t">货厢容积(m)</span>
                <span class="v">约4.4</span>
              </li>
              <li>
                <span class="t">质保(电机、电控、动力电池）(km）</span>
                <span class="v">5年/20万</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <transition name="modal">
      <div class="popup" v-if="popupShow">
        <div class="popupMasker"></div>
        <div class="popupContent">
          <div class="bookView" v-show="!submited">
            <span class="close" @click="hidePopup"></span>
            <div class="tips">
              <h3 class="title">订购说明</h3>
              <p>请如实填写申请资料，我们的销售人员会及时和你联系订购信息</p>
            </div>

            <form @submit="submitPopup">
              <ul class="formList">
                <li class="item">
                  <label for="name">姓名</label>
                  <div class="input">
                    <input
                      id="name"
                      v-model.trim="formData.name"
                      name="name"
                      type="text"
                      maxlength="20"
                      placeholder="请输入你的姓名"
                    />
                    <p class="error" v-show="formDataCheck.name">请输入你的姓名</p>
                  </div>
                </li>
                <li class="item">
                  <label for="mobile">手机号</label>
                  <div class="input">
                    <input
                      id="mobile"
                      v-model.trim="formData.mobile"
                      name="mobile"
                      type="text"
                      maxlength="20"
                      placeholder="请输入你的手机号"
                    />
                    <p class="error" v-show="formDataCheck.mobile">请输入你的手机号</p>
                  </div>
                </li>
                <li class="item" style="border-bottom: none">
                  <label for="area">所在城市</label>
                  <div class="input">
                    <input
                      id="area"
                      v-model.trim="formData.area"
                      name="area"
                      type="text"
                      maxlength="20"
                      placeholder="请输入所在城市"
                    />
                    <p class="error" v-show="formDataCheck.area">请输入所在城市</p>
                  </div>
                </li>
              </ul>
              <ul class="formList">
                <li class="item itemShow">
                  <label for="remark">备注</label>
                  <textarea
                    id="remark"
                    v-model.trim="formData.remark"
                    name="remark"
                    placeholder="请输入需要备注的信息"
                  ></textarea>
                </li>
              </ul>
              <div class="formBtnLine">
                <button class="btn" type="submit">申请订购</button>
              </div>
            </form>
          </div>

          <div class="backView" v-show="submited">
            <template v-if="success">
              <img
                class="icon"
                src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/09/icon_success.png"
                width="60"
                height="60"
              />
              <h2 class="title">恭喜您，提交成功！</h2>
              <p>我们的销售人员会在1-3个工作日和您联系</p>
            </template>
            <template v-else>
              <img
                class="icon"
                src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/09/icon_fail.png"
                width="60"
                height="60"
              />
              <h2 class="title">Sorry，提交失败~</h2>
            </template>
            <div class="btnLine">
              <button class="btn" type="button" @click="hidePopup">知道了</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "csX2",
  data() {
    return {
      formData: {
        name: '',
        mobile: '',
        area: '',
        remark: '',
      },
      formDataCheck: {
        name: false,
        mobile: false,
        area: false,
      },
      popupShow: false,
      submited: false,
      success: false,
    }
  },
  methods: {
    showPopup() {
      this.popupShow = true;
      this.submited = false;
    },
    hidePopup() {
      this.popupShow = false;
      this.submited = false;
    },
    submitPopup(e) {
      e.preventDefault();

      const { formData, formDataCheck } = this;
      let verify;

      Object.keys(formDataCheck).forEach((key) => {
        formDataCheck[key] = !formData[key];

        if (formDataCheck[key]) {
          verify = true;
        }
      });

      if (verify) return;

      this.axios({
        url: 'https://api.partner.juzhen.cn/api/api/preorder/save',
        method: "post",
        data: {
          count: 1,
          prodId: 83,
          ...formData,
        }
      }).then((data) => {
        this.submited = true;
        this.success = data.code === 200;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.layout {
  .inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
  }
}

.popup {
  position: relative;
  z-index: 999;
}

.popupMasker,
.popupContent {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.popupMasker {
  background-color: rgba(0, 0, 0, .7);
  pointer-events: auto;
}

.popupContent {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.bookView,
.backView {
  position: relative;
  margin: 36px;
  padding: 16px;
  background-color: #F7F8F9;
  border-radius: 16px;
}

.bookView {
  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    transition: transform .3s;
    cursor: pointer;

    &:hover {
      transform: rotate(180deg);
    }
    
    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1px;
      width: 100%;
      height: 2px;
      content: '';
      transform: rotate(45deg);
      background-color: #979797;
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  .tips {
    margin-bottom: 20px;
    padding: 0 36px 0 16px;
    font-size: 14px;
    color: #949B9C;
    line-height: 16px;

    .title {
      display: block;
      margin-bottom: 8px;
      font-size: 16px;
      color: #323334;
      line-height: 36px;
    }
  }
}

.backView {
  padding: 24px 16px;
  font-size: 14px;
  color: #949B9C;
  text-align: center;
  line-height: 16px;

  .title {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    color: #323334;
  }

  .btnLine {
    margin: 40px auto 0;
    width: 50%;

    .btn {
      display: block;
      width: 100%;
      height: 32px;
      padding: 0;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 32px;
      border-radius: 28px;
      background: #e83e0b;
      border: none;
      outline: none;
    }
  }
}

.formList {
  margin: 12px 0;
  padding: 10px 16px;
  background-color: #fff;
  border-radius: 8px;

  .item {
    display: flex;
    flex-wrap: wrap;
    line-height: 40px;
    border-bottom: 1px solid #D8D8D8;

    label {
      width: 80px;
      font-size: 14px;
      color: #323334;
      font-weight: bold;
    }

    .error {
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      color: #f00;
    }
  }

  .itemBlock {
    display: block;
    border-bottom: none;
  }

  .input {
    flex: 1;
    padding: 4px 0;

    input {
      display: block;
      padding: 4px 0;
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      border: none;
      outline: none;
    }
  }

  textarea {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    border: none;
    outline: none;
    resize: none;
  }
}

.formBtnLine {
  margin: 16px;

  .btn {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 40px;
    border-radius: 28px;
    background: #e83e0b;
    border: none;
    outline: none;
  }
}

.banner, .box {
  position: relative;

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.banner {
  padding: 10% 0 8%;
  text-align: center;
  font-size: 24px;
  line-height: 28px;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(242px + 45%);
    content: '';
    background : linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.08) 2%, rgba(0, 0, 0, 0.12) 100%);
    z-index: 1;
  }

  .layout {
    position: relative;
    z-index: 5;
  }

  h2 {
    margin-bottom: 12px;
    font-size: 62px;
    font-weight: normal;
    line-height: 72px;
    font-family: PingFangSC-Ultralight;

    .logo {
      vertical-align: top;
    }
  }

  .slogan {
    span {
      margin: 0 20px;
    }
  }

  .btnBook {
    display: inline-block;
    margin: 40px auto 30px;
    vertical-align: top;
    padding: 0 60px;
    line-height: 60px;
    font-size: 34px;
    color: #fff;
    border-radius: 60px;
    background-image: linear-gradient(#f34600 0%, #ef2e00 100%);
    cursor: pointer;
  }
}

.box {
  .summary {
    font-size: 24px;
    line-height: 28px;

    h3 {
      margin-bottom: 10px;
      font-size: 62px;
      line-height: 74px;
    }
  }
}

.volumeBox {
  margin-bottom: #{"min(18%, 350px)"};
  background-color: #C5DBDD;

  .inner {
    position: relative;
    padding: 12.5% 20px 15.625%;

    .summary {
      position: relative;
      padding-bottom: 8px;
      z-index: 5;
    }

    img {
      position: absolute;
      right: 0;
      top: 33.3334%;
      width: 81.25%;
      z-index: 1;
    }
  }
}

.passBox {
  margin-bottom: #{"min(10%, 180px)"};

  .layout {
    padding: 0;
  }

  .inner {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    .summary {
      flex: 46;
      padding: 30px 40px;
      background-color: #fff;
      min-width: 380px;
      transform: translateX(-100px);
    }

    .img {
      flex: 98;
    }
  }
}

.qualityBox {
  margin-bottom: 300px;
  background-color: #E2491C;

  .inner {
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .summary {
      margin-bottom: -70px;
      color: #fff;
      text-align: center;

      h3 {
        color: #fff;
      }
    }

    img {
      position: relative;
      top: 160px;
      max-width: 80%;
    }
  }
}

.paramsBox {
  margin-bottom: 160px;

  .inner {
    display: flex;

    .img {
      position: relative;
      left: -20px;
      flex: 95;
    }

    .params {
      flex: 49;
      min-width: 470px;

      h3 {
        margin-bottom: 24px;
        font-size: 22px;
        line-height: 28px;
      }

      .list {
        border-top: 1px dashed #666;

        li {
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          line-height: 44px;
          color: #666;
          border-bottom: 1px dashed #666;
        }
      }
    }
  }
}


@media only screen and (max-width: 500px) {
  strong {
    font-weight: bold;
  }

  .banner {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 80px);
    padding: 90px 0 60px;
    text-align: center;
    font-size: 12px;
    line-height: 28px;

    &::after {
      height: 76%;
    }

    h2 {
      margin-bottom: 10px;
      font-size: 36px;
      line-height: 46px;
      font-family: PingFang SC;

      .logo {
        display: block;
        margin: 0 auto;
        width: 140px;
        height: 52px;
      }
    }

    .slogan span {
      margin: 0 8px;
    }
  
    .btnBook {
      margin: 22px auto 48px;
      padding: 0 36px;
      font-size: 20px;
      line-height: 36px;
      border-radius: 36px;
      cursor: pointer;
    }
  }

  .box {
    margin-bottom: 0;
    display: flex;
    min-height: 100vh;
    align-items: center;

    .summary {
      margin-bottom: 20%;
      font-size: 20px;
      line-height: 28px;
      text-align: center;

      h3 {
        margin-bottom: 8px;
        font-size: 50px;
        line-height: 58px;
      }
    }
  }

  .volumeBox {
    .inner {
      padding: 60px 0;

      img {
        position: static;
        width: 100%;
      }
    }
  }

  .passBox {
    align-items: flex-start;

    .inner {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: flex-end;

      .summary {
        flex: none;
        padding: 20px 30px;
        min-width: auto;
        background-color: #fff;
        transform: translateY(-24px);
      }

      .img {
        flex: none;
      }
    }
  }

  .qualityBox {
    .inner {
      display: block;
      padding: 60px 0;
      width: 90%;

      .summary {
        margin-bottom: 30%;
        color: #fff;
        text-align: center;

        h3 {
          color: #fff;
        }
      }

      img {
        position: static;
        max-width: 100%;
      }
    }
  }

  .paramsBox {
    .inner {
      display: block;
      padding: 60px 0;

      .img {
        margin-bottom: 36px;
        position: static;
      }

      .params {
        min-width: 100%;

        h3 {
          margin-bottom: 12px;
          font-size: 16px;
          line-height: 20px;
        }

        .list {
          li {
            font-size: 14px;
            line-height: 34px;
          }
        }
      }
    }
  }
}
</style>